.modal-body[data-v-6aeff8ea] {
  height: 550px;
  justify-content: center;
}
@media all and (max-width: 991px) {.modal-body[data-v-6aeff8ea][data-v-6aeff8ea] {
    height: 400px;
}
}
@media all and (max-width: 475px) {.modal-body[data-v-6aeff8ea][data-v-6aeff8ea] {
    height: 350px;
}
}
@media all and (max-width: 475px) {.modal-body[data-v-6aeff8ea][data-v-6aeff8ea] {
    flex-direction: column;
}
}
@media all and (max-width: 475px) {.modal-body[data-v-6aeff8ea][data-v-6aeff8ea] {
    justify-content: space-evenly;
}
}
