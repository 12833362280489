
.btn[data-v-989d8a43] {
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
}
.btn-mobile[data-v-989d8a43] {
  height: 30px;
  width: 30px;
  padding: 6px;
}
.title[data-v-989d8a43] {
  padding: 20px;
  padding-block-start: 20px;
  padding-block-end: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.title > h3[data-v-989d8a43] {
    margin-bottom: 0;
    font-weight: 400;
    padding-inline: 60px;
}
.mobile-title[data-v-989d8a43] {
  padding-block-start: 20px;
  padding-block-end: 20px;
  margin-inline-start: 20px;
  margin-inline-end: 20px;
  background-color: #f8f9fa;
  color: black;
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: bold;
}
.mobile-title > h3[data-v-989d8a43] {
    margin-bottom: 0;
    font-weight: 500;
    padding-inline: 55px;
}
