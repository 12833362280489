
.grid-label[data-v-3ec6c835] {
  display: block;
  margin-block-start: 8px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.list-label[data-v-3ec6c835] {
  display: block;
  overflow: hidden;
  font-weight: 600;
}
.img-wrapper[data-v-3ec6c835] {
  width: 75px;
  display: flex;
  justify-content: flex-end;
}
.text-content[data-v-3ec6c835] {
  flex-grow: 0;
  width: 80%;
}
@container gallery (max-width: 410px) {
.img-wrapper[data-v-3ec6c835] {
    display: none;
}
.text-content[data-v-3ec6c835] {
    flex-grow: 1;
    width: 100%;
}
}
.horizontal[data-v-3ec6c835] {
  display: none;
}
.stack[data-v-3ec6c835] {
  display: flex;
  width: 100%;
}
@container gallery (max-width: 250px) {
.img-wrapper[data-v-3ec6c835] {
    display: block;
    width: auto;
}
.text-content[data-v-3ec6c835] {
    width: 100%;
    flex-grow: 0;
}
.horizontal[data-v-3ec6c835] {
    display: none;
}
.stack[data-v-3ec6c835] {
    display: none;
}
.list-label[data-v-3ec6c835] {
    display: block;
    margin-block-start: 8px;
    overflow: hidden;
    text-align: center;
    width: 100%;
    font-weight: normal;
}
}
@container gallery (min-width: 752px) {
.text-content[data-v-3ec6c835] {
    flex-grow: 1;
}
.horizontal[data-v-3ec6c835] {
    display: block;
}
.stack[data-v-3ec6c835] {
    display: none;
}
}
.fixed-height[data-v-3ec6c835] {
  height: 90px;
  overflow-x: hidden;
  line-height: 1.55;
}
