
.form-overlay[data-v-89d6761a] {
  --bs-bg-opacity: 0.9;

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content[data-v-89d6761a] {
  width: 800px;
}
