.dashboard-container[data-v-874d6740] {
  margin-block-end: 40px;
}
@media all and (max-width: 718px) {.dashboard-container[data-v-874d6740][data-v-874d6740] {
    border-bottom: 0;
}
}
@media all and (max-width: 718px) {.dashboard-container[data-v-874d6740][data-v-874d6740] {
    overflow: auto;
}
}
