
.legend[data-v-b41ced18] {
  padding: 0;
  margin-inline: 0;
  margin-block: 5px;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: var(--bs-body-line-height);
}
.offcanvas-wrapper .legend[data-v-b41ced18] {
    font-size: 1.2rem;
    margin-block-start: 1rem;
}
