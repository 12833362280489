
.btn.btn-grid {
  padding: 6px;
  padding-inline: 8px;
}
#dashboard-help-button {
  font-size: 18px;
  font-weight: normal;
  padding: 8px;
  margin-top: 2px;
  width: 38px;
  margin-left: 5px;
}
.dashboard-top-bar {
  padding: 1rem;
}
.dashboard-top-bar__left {
    display: flex;
    align-items: center;
}
.dashboard-top-bar__left > *:not(:last-child) {
      margin-inline-end: 10px;
}
.dashboard-top-bar__add-toggle {
    border: none;
    background: none;
    color: #363a40;
    position: relative;
}
.dashboard-top-bar__add-toggle.dropdown-toggle-no-caret::after {
        display: none !important;
}
.dashboard-top-bar__add-toggle:hover,
    .dashboard-top-bar__add-toggle:focus-visible,
    .dashboard-top-bar__add-toggle:focus {
      transition: none;
      background: none !important;
      color: #363a40 !important;
      border: none !important;
}
.dashboard-top-bar__add-toggle:hover {
      box-shadow: none !important;
}
