
.max-600[data-v-9c8cb7e3] {
  max-width: 600px;
}
.btn.form-control[data-v-9c8cb7e3] {
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  border: 1px solid #ced4da;
}
.btn.form-control[data-v-9c8cb7e3]:not(:hover) {
    background: #ffffff;
}
