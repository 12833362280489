
.settings-header[data-v-6e38208b] {
  display: flex;
  justify-content: center;
  padding-block: 20px;
}
.settings-bg[data-v-6e38208b] {
  height: 300px;
  width: 500px;
  padding-inline: 40px;
}
.settings-form[data-v-6e38208b] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
}
