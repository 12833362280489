.table-wrapper[data-v-be670b4f] {
  width: 100%;
  border-radius: 5px;
  border: 1px solid;
}
@media all and (max-width: 400px) {.table-wrapper[data-v-be670b4f][data-v-be670b4f] {
    overflow-x: auto;
}
}
.mobile-table-wrapper[data-v-be670b4f] {
  border: none !important;
  border-radius: 0 !important;
}
.mobile-table-wrapper th[data-v-be670b4f] {
    border-radius: 0 !important;
}
