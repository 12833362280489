
.filters-wrapper > * + * {
    margin-block-start: 15px;
}
.offcanvas-filters-wrapper {
  background: #f0fdfa;
}
.offcanvas-filters-wrapper > * + * {
    margin-block-start: 20px;
}
