.prev[data-v-abe1432a] {
height: 30px;
width: 30px;
position: absolute;
bottom: 50%;
color: #d1d1d1;
cursor: pointer;
}
.prev[data-v-abe1432a][data-v-abe1432a]:not(:disabled):hover,
  .prev[data-v-abe1432a][data-v-abe1432a]:not(:disabled):focus-visible {
    color: #006c69;
}
.prev[data-v-abe1432a][data-v-abe1432a]:disabled {
    cursor: default;
}
@media all and (max-width: 991px) {
.prev[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    height: 25px;
}
}
@media all and (max-width: 991px) {
.prev[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    width: 25px;
}
}
@media all and (max-width: 475px) {
.prev[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    position: static;
}
}
.prev[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
  left: 20px;
}
@media all and (max-width: 991px) {.prev[data-v-abe1432a][data-v-abe1432a] {
    left: 10px;
}
}
@media all and (max-width: 1380px) {
.global .prev[data-v-abe1432a][data-v-abe1432a] {
    position: static;
}
}
.next[data-v-abe1432a] {
height: 30px;
width: 30px;
position: absolute;
bottom: 50%;
color: #d1d1d1;
cursor: pointer;
}
.next[data-v-abe1432a][data-v-abe1432a]:not(:disabled):hover,
  .next[data-v-abe1432a][data-v-abe1432a]:not(:disabled):focus-visible {
    color: #006c69;
}
.next[data-v-abe1432a][data-v-abe1432a]:disabled {
    cursor: default;
}
@media all and (max-width: 991px) {
.next[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    height: 25px;
}
}
@media all and (max-width: 991px) {
.next[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    width: 25px;
}
}
@media all and (max-width: 475px) {
.next[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
    position: static;
}
}
.next[data-v-abe1432a][data-v-abe1432a][data-v-abe1432a] {
  right: 20px;
}
@media all and (max-width: 991px) {
.next[data-v-abe1432a][data-v-abe1432a] {
    right: 10px;
}
}
@media all and (max-width: 1380px) {
.global .next[data-v-abe1432a][data-v-abe1432a] {
    position: static;
}
}
.wrapper[data-v-abe1432a] {
  align-items: center;
  height: 500px;
}
@media all and (max-width: 475px) {
.wrapper[data-v-abe1432a][data-v-abe1432a] {
    height: 300px;
}
}
@media all and (max-width: 991px) {
.attachment-modal .wrapper[data-v-abe1432a][data-v-abe1432a] {
    height: 350px;
}
}
.button-unstyled[data-v-abe1432a] {
  outline: 0;
  border: none;
  appearance: none;
  background: none;
  height: max-content;
  padding: 0;
}
