
.img-sm[data-v-329211b0] {
  height: 40px;
  width: 100%;
  object-fit: contain;
  object-position: 100% 50%;
}
.img-lg[data-v-329211b0] {
  height: 120px;
  width: 170px;
  object-fit: cover;
  object-position: 50% 50%;
}
.img-modal[data-v-329211b0] {
  height: 100%;
  width: 80%;
  object-fit: contain;
  object-position: 50% 50%;
}
.img-gallery[data-v-329211b0] {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}
@container gallery (max-width: 250px) {
.img-sm[data-v-329211b0] {
    height: 120px;
    width: 170px;
    object-fit: cover;
    object-position: 50% 50%;
}
}
