
.item-outer {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
}
.item-outer:hover {
    background-color: #f0fdfa;
}
.item-inner-grid {
  flex-direction: column;
  justify-content: center;
  padding-inline: 1rem;
  height: 150px;
}
.item-inner-list {
  justify-content: space-between;
  flex-direction: row-reverse;
}
@container gallery (max-width: 752px) {
.item-outer {
    padding-bottom: 0;
}
}
@container gallery (max-width: 250px) {
.item-inner {
    flex-direction: column;
    justify-content: center;
    padding-inline: 1rem;
    height: 150px;
}
}
