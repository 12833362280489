
@container gallery (min-width: 835px) {
.gallery-container[data-v-d5ce268d] {
    padding-inline: var(--bs-gutter-x);
}
}
.gallery-container.global[data-v-d5ce268d] {
  padding: 0;
  display: flex;
  gap: 1.5rem;
}
.gallery-container.global .content[data-v-d5ce268d] {
    display: flex;
    flex-direction: column;
}
