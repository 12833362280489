
.nesting[data-v-e91fd47e] {
  border-left: 1px solid #006c69;
  margin-inline-start: 0.5rem;
  margin-block-start: 0.5rem;
}
.nested[data-v-e91fd47e] {
  padding-inline-start: 0.75rem;
}
.collapsed[data-v-e91fd47e] {
  display: none;
}
.internal[data-v-e91fd47e] {
  position: relative;
  background: rgba(255, 248, 233, 1);
  border-radius: 2px;
}
.internal[data-v-e91fd47e]:not(.parent-private) {
    border-right: 3px solid rgba(255, 184, 29, 0.65);
}
.internal.resolved[data-v-e91fd47e] {
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 248, 233, 1),
      rgba(255, 248, 233, 1) 3px,
      #ffffff 3px,
      #ffffff 6px
    );
}
.internal.comment--sorting[data-v-e91fd47e]:hover {
    background: rgba(252, 240, 217, 1);
}
.resolved[data-v-e91fd47e] {
  background: repeating-linear-gradient(
    45deg,
    #f8f8f8,
    #f8f8f8 3px,
    #ffffff 3px,
    #ffffff 6px
  );
}
.resolved--shown[data-v-e91fd47e] {
    opacity: 1;
}
.permalinked[data-v-e91fd47e]:focus-visible,
  .permalinked[data-v-e91fd47e]:focus {
    outline: 2px solid #006c69;
}
.watermark[data-v-e91fd47e] {
  font-weight: bold;
  margin-inline-start: auto;
  text-transform: uppercase;
  color: rgba(255, 184, 29, 0.3);
  font-size: 0.85rem;
  position: absolute;
  top: 0.35rem;
  right: 1rem;
  pointer-events: none;
}
