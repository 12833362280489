
.form-label[data-v-08107859] {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg[data-v-08107859] {
  font-size: 0.95rem;
}
.list-wrapper[data-v-08107859] {
  height: 350px;
  max-height: 60%;
  overflow: hidden;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  margin-block-start: -1rem;
}
.files-list[data-v-08107859] {
  height: 100%;
  margin: 0;
  padding-inline: 0;
  list-style-type: none;
  overflow-y: auto;
}
.files-list-item[data-v-08107859] {
  width: 100%;
  padding-block: 0.5rem;
  border-bottom: 1px solid #ced4da;
  padding-inline: 1rem;
}
.row[data-v-08107859] {
  display: grid;
  grid-template-columns: 80px auto 40px;
  align-items: center;
  gap: 1rem;
}
.filename[data-v-08107859] {
  font-weight: 600;
  margin-block-end: 0.5rem;
}
.image-wrapper[data-v-08107859] {
  display: flex;
  justify-content: center;
  width: 100%;
}
.image[data-v-08107859] {
  height: 64px;
  width: 64px;
  padding: 2px;
  object-fit: contain;
  object-position: 50% 50%;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background-color: #ffffff;
}
.icon[data-v-08107859] {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: 64px;
  width: 64px;
  padding: 2px;
  background-color: #ffffff;
}
.icon .icon-lg[data-v-08107859] {
    height: 56px;
}
.delete-file[data-v-08107859] {
  width: 36px;
  border: none;
  padding: 8px;
  margin-left: -8px;
  margin-top: 6px;
  color: black;
}
.delete-file[data-v-08107859]:hover {
    background-color: #ffffff;
    border: none;
    color: #d73b35;
}
