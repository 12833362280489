
.legend[data-v-88fe0de1] {
  padding: 0;
  margin: 0;
  border: none;
  font-size: 0.9rem;
  font-weight: 600;
  font-variant: small-caps;
  line-height: var(--bs-body-line-height);
}
@container gallery (min-width: 995px) {
.wrapper.filter-col[data-v-88fe0de1] {
    max-width: 34%;
}
}
.global .wrapper[data-v-88fe0de1],
.offcanvas .wrapper[data-v-88fe0de1] {
  max-width: 100%;
  margin-block: 5px;
}
.flex-column[data-v-88fe0de1] {
  gap: 15px;
}
.offcanvas .flex-column[data-v-88fe0de1] {
  gap: 20px;
}
.global .d-flex[data-v-88fe0de1],
.offcanvas .d-flex[data-v-88fe0de1] {
  flex-direction: column !important;
}
