.detail-container[data-v-1992f858] {
  container: detail / inline-size;
}
.modal-body[data-v-1992f858] {
  height: 550px;
  width: 100%;
  max-width: 1000px;
  justify-content: center;
}
@media all and (max-width: 475px) {
.modal-body[data-v-1992f858][data-v-1992f858] {
    height: 350px;
}
}
@media all and (max-width: 1380px) {
.modal-body[data-v-1992f858][data-v-1992f858] {
    flex-direction: column;
}
}
@media all and (max-width: 475px) {
.modal-body[data-v-1992f858][data-v-1992f858] {
    justify-content: space-evenly;
}
}
