
.form-switch[data-v-e0246d34] {
  display: flex;
  gap: 15px;
  padding-left: 0;
  align-items: center;
}
.form-check-input.switch[data-v-e0246d34] {
  width: 50px;
  height: 30px;
  margin-left: 0;
  flex-shrink: 0;
  margin-top: 0;
}
