
.utility[data-v-bb1bd76f] {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.utility > button[data-v-bb1bd76f] {
    width: 43px;
    height: 38px;
}
