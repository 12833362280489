.form-overlay[data-v-3b9b8300] {
  --bs-bg-opacity: 0.9;

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 5%;
  border-radius: 4px;
}
@media all and (max-width: 718px) {.form-overlay[data-v-3b9b8300][data-v-3b9b8300] {
    margin-inline-start: -40px;
}
}
@media all and (max-width: 718px) {.form-overlay[data-v-3b9b8300][data-v-3b9b8300] {
    width: calc(100% + 80px);
}
}
@media all and (max-width: 718px) {.form-overlay[data-v-3b9b8300][data-v-3b9b8300] {
    border: none;
}
}
@media all and (max-width: 718px) {.form-overlay[data-v-3b9b8300][data-v-3b9b8300] {
    border-bottom: 1px solid;
}
}
