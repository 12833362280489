
.widget-table-td[data-v-7b4949e5] {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-block: 10px !important;
  width: 100%;
  background: #ffffff;
}
tr[data-v-7b4949e5]:last-child {
  border: none;
}
tr:last-child td[data-v-7b4949e5] {
    border: none;
}
tr:last-child td[data-v-7b4949e5]:first-child {
    border-bottom-left-radius: 5px;
}
tr:last-child td[data-v-7b4949e5]:last-child {
    border-bottom-right-radius: 5px;
}
.description-field[data-v-7b4949e5] {
  display: inline-block;
  height: var(--height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
}
.stretched-link[data-v-7b4949e5] {
  opacity: 0;
}
