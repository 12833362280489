.wrapper[data-v-14cd0262] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 0.5rem;
  padding-block-start: 0.75rem;
  border-top: 1px solid #dee2e6;
  font-size: 0.7875rem;
}
.wrapper button[data-v-14cd0262] {
    display: flex;
    gap: 0.5rem;
    height: 1.5rem;
    align-items: center;
}
.wrapper button svg[data-v-14cd0262] {
      height: 16px;
      width: 16px;
}
@media all and (max-width: 650px) {
.wrapper button span[data-v-14cd0262][data-v-14cd0262] {
    display: none;
}
}
.wrapper button[data-v-14cd0262]:focus-visible {
      outline: 1px solid;
}
.actions[data-v-14cd0262] {
  display: flex;
  gap: 1rem;
}
@media all and (max-width: 516px) {
.actions[data-v-14cd0262][data-v-14cd0262] {
    gap: 0.1rem;
}
}
.actions--narrow[data-v-14cd0262] {
    gap: 0.1rem;
}
