
.toggle[data-v-ce81501d] {
  background: transparent;
  border: none;
  appearance: none;
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  pointer-events: auto;
  opacity: 1;
  color: #777;
}
.toggle > *[data-v-ce81501d] {
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    background: var(--Overlay-bg);
}
.toggle[data-v-ce81501d]:disabled {
    color: var(--bs-body-color);
}
.overlay[data-v-ce81501d] {
  --Overlay-bg: rgba(247, 247, 247, 1);
  --Overlay-bg-transparent: rgba(247, 247, 247, 0);
}
.overlay--internal[data-v-ce81501d] {
    --Overlay-bg: rgba(255, 248, 233, 1);
    --Overlay-bg-transparent: rgba(255, 248, 233, 0);
}
.overlay[data-v-ce81501d] {

  position: absolute;
  top: 0;
  block-size: 100%;
  inline-size: 100%;
  opacity: 1;
  background: linear-gradient(
    var(--Overlay-bg-transparent),
    50%,
    var(--Overlay-bg)
  );
  transition: opacity 500ms ease 25ms, visibility 500ms;
  pointer-events: none;
}
.overlay--expanded[data-v-ce81501d] {
    opacity: 0;
}
