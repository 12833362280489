
.icon-sm[data-v-1f829c76] {
  height: 40px;
  margin-left: -10px;
}
.icon-lg[data-v-1f829c76] {
  height: 60px;
  margin-left: -15px;
}
@container gallery (max-width: 250px) {
.icon-sm[data-v-1f829c76] {
    height: 60px;
    margin-left: -15px;
}
}
