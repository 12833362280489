.widget-table[data-v-5a89c737] {
  margin-bottom: 0;
  width: 100%;
  table-layout: fixed;
}
.widget-table thead[data-v-5a89c737] {
    background: none;
}
@media all and (max-width: 400px) {
.widget-table[data-v-5a89c737][data-v-5a89c737] {
    width: 500px;
  }
}
.widget-table > tbody[data-v-5a89c737] {
    border-top: 1px solid !important;
}
.widget-table > tbody tr[data-v-5a89c737] {
      position: relative;
}
.widget-table > tbody tr:hover > td[data-v-5a89c737]:not(.no-records), .widget-table > tbody tr:focus-visible > td[data-v-5a89c737]:not(.no-records) {
          --bs-table-accent-bg: #f0fdfa;
}
.widget-th[data-v-5a89c737] {
  width: var(--thW, auto);
  background: #e9ecef;
  font-weight: normal;
}
.widget-th-text[data-v-5a89c737] {
    display: inline-block;
    height: 21px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.widget-th-sortable[data-v-5a89c737] {
    color: currentColor;
    cursor: pointer;
}
.widget-th-description[data-v-5a89c737] {
    width: var(--descW, auto);
}
.widget-th-status[data-v-5a89c737] {
    width: var(--statusW, --thW);
}
.widget-th[data-v-5a89c737]:first-child {
    border-top-left-radius: 4px;
}
@media all and (max-width: 718px) {
.widget-th[data-v-5a89c737][data-v-5a89c737]:first-child {
    border-radius: 0;
}
}
.widget-th[data-v-5a89c737]:last-child {
    border-top-right-radius: 4px;
}
@media all and (max-width: 718px) {
.widget-th[data-v-5a89c737][data-v-5a89c737]:last-child {
    border-radius: 0;
}
}
.count-4[data-v-5a89c737] {
  --thW: 25%;
  --descW: 30%;
  --statusW: 15%;
  --height: 64.8px;
}
.count-5[data-v-5a89c737] {
  --thW: 20%;
  --descW: 27%;
  --statusW: 13%;
  --height: 64.8px;
}
.count-6[data-v-5a89c737] {
  --thW: 17%;
  --descW: 22%;
  --statusW: 10%;
  --height: 43.2px;
}
.count-7[data-v-5a89c737] {
  --thW: 14.5%;
  --descW: 19.5%;
  --statusW: 8%;
  --height: 43.2px;
}
.count-8[data-v-5a89c737] {
  --thW: 12%;
  --descW: 17%;
  --statusW: 5%;
  --height: 43.2px;
}
.count-9[data-v-5a89c737] {
  --thW: 11%;
  --descW: 18%;
  --statusW: 5%;
  --height: 43.2px;
}
.count-10[data-v-5a89c737] {
  --thW: 10%;
  --descW: 15%;
  --statusW: 5%;
  --height: 43.2px;
}
.no-records[data-v-5a89c737] {
  text-align: center;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
@media all and (max-width: 718px) {
.no-records[data-v-5a89c737][data-v-5a89c737] {
    border: none;
}
}
