.legend[data-v-d5e2c06e] {
  padding: 0;
  margin-inline: 0;
  margin-block: 5px;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  font-variant: small-caps;
  line-height: var(--bs-body-line-height);
}
.offcanvas-filters-wrapper .legend[data-v-d5e2c06e] {
    font-size: 1.5rem;
    margin-block-start: 1rem;
}
.filters-wrapper[data-v-d5e2c06e] {
  width: 300px;
  height: max-content;
  flex-shrink: 0;
  background: #f0fdfa;
}
@media all and (max-width: 1200px) {
.filters-wrapper[data-v-d5e2c06e][data-v-d5e2c06e] {
    display: none;
}
}
hr[data-v-d5e2c06e] {
  margin-block: 20px;
}
