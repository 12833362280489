
.list-header[data-v-e4ffafaf] {
  background-color: #f0f1f2;
  line-height: 2;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bold;
}
@container gallery (max-width: 751px) {
.list-header[data-v-e4ffafaf] {
    display: none;
}
}
.thumb[data-v-e4ffafaf] {
  width: 75px;
  text-align: right;
}
