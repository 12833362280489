
.legend[data-v-0bbaac97] {
  padding: 0;
  margin: 0;
  border: none;
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
  line-height: var(--bs-body-line-height);
}
@container gallery (min-width: 995px) {
.wrapper.filter-col[data-v-0bbaac97] {
    max-width: 34%;
}
}
.global .wrapper[data-v-0bbaac97],
.offcanvas .wrapper[data-v-0bbaac97] {
  width: 100%;
}
.global .d-flex[data-v-0bbaac97] {
  flex-direction: column !important;
}
@container gallery (max-width: 500px) {
.test[data-v-0bbaac97] {
    flex-direction: column !important;
}
}
