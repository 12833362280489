
.grid[data-v-94f37d86] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.list > * + *[data-v-94f37d86] {
    margin-top: 1rem;
}
@container gallery (max-width: 250px) {
.list[data-v-94f37d86],
  .grid[data-v-94f37d86] {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: 0;
}
}
