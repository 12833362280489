
.dashboard-grid {
  margin-inline-start: -20px;
  width: calc(100% + 40px);
}
.dashboard-grid .vue-grid-item.vue-grid-placeholder {
    background-color: #006c69;
}
.vue-grid-item {
  touch-action: none;
}
