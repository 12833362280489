
.wrapper[data-v-8ef42710] {
  margin-left: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.5rem;
  margin-top: -0.5rem;
  padding-bottom: 1.26rem;
  width: max-content;
  flex-shrink: 0;
  outline: 2px solid #f0f1f2;
  background-color: #f0f1f2;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 2;
  font-weight: bold;
}
