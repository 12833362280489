.visibility[data-v-5f336d22] {
  display: flex;
  gap: 0.5rem;
  height: 1.5rem;
  align-items: center;
}
.visibility svg[data-v-5f336d22] {
    height: 16px;
    width: 16px;
}
@media all and (max-width: 516px) {
.visibility span[data-v-5f336d22][data-v-5f336d22] {
    display: none;
}
}
.visibility[data-v-5f336d22]:focus-visible {
    outline: 1px solid;
}
.anchor[data-v-5f336d22] {
  display: block;
  border-bottom-left-radius: 4px;
  width: 16px;
  height: 10px;
  border-left: 1px solid;
  border-bottom: 1px solid;
  margin-inline-start: 12px;
  margin-inline-end: 6px;
  margin-block-start: -5px;
}
