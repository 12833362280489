
.modal-footer[data-v-1e6907a8] {
  justify-content: flex-end;
  gap: 0.5rem;
}
@container detail (max-width: 410px) {
.modal-footer[data-v-1e6907a8] {
    display: block;
}
}
.button-wrapper[data-v-1e6907a8] {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
@container detail (max-width: 410px) {
.button-wrapper[data-v-1e6907a8] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-inline: 0;
}
}
@container detail (max-width: 410px) {
.btn-outline-danger[data-v-1e6907a8] {
    width: 100%;
    margin-top: 0.25rem;
}
}
.global .modal-footer[data-v-1e6907a8] {
  border: none;
  min-width: min(100%, 300px);
}
