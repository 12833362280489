
.pagination-list[data-v-e74d9544] {
  bottom: 0.75rem;
  list-style: none;
  cursor: pointer;
}
.disabled[data-v-e74d9544] {
  cursor: auto;
}
.page-link[data-v-e74d9544] {
  padding: 9px 17px;
}
.page-item.active .page-link[data-v-e74d9544] {
  color: #006c69;
  background-color: #f0fdfa;
  border-color: #a0d0cb;
}
@container gallery (max-width: 250px) {
.hide-xs[data-v-e74d9544] {
    display: none;
}
}
@media all and (max-width: 375px) {
.hide-xs[data-v-e74d9544] {
    display: none;
}
}
