
.wrapper[data-v-6b4d04cb] {
  background: #f0fdfa;
}
.filter-col[data-v-6b4d04cb] {
  width: 100%;
}
@container gallery (min-width: 695px) {
.filter-col[data-v-6b4d04cb] {
    max-width: 50%;
}
}
@container gallery (min-width: 995px) {
.filter-col[data-v-6b4d04cb] {
    max-width: 22%;
}
}
