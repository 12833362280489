.widget-container {
  height: 100%;
}
@media all and (max-width: 718px) {
.widget-container.shadow {
    box-shadow: none !important;
}
}
.widget-container + .vue-resizable-handle {
    right: -5px !important;
    bottom: -5px !important;
    width: 12px !important;
    height: 12px !important;
    background: none !important;
    background-color: #363a40 !important;
    border: 2px solid #363a40;
    border-radius: 2px;
}
@media all and (max-width: 718px) {
.widget-container + .vue-resizable-handle {
    right: -25px !important;
}
}
.widget-container--hide-resize + .vue-resizable-handle {
      display: none !important;
}
