
.form-label[data-v-e3feda85] {
  font-size: 0.8rem;
  font-weight: 600;
  font-variant: small-caps;
}
.label-lg[data-v-e3feda85] {
  font-size: 0.95rem;
}
.files-input[data-v-e3feda85] {
  color: #ffffff;
  font-size: 0.35rem;
  border-radius: 8px;
  padding: 8px;
  max-width: 108px;
}
.files-input[data-v-e3feda85]:focus-visible {
    outline: 0;
}
input[data-v-e3feda85]:focus-visible::file-selector-button {
  outline: 4px solid rgba(0, 108, 105, 0.25);
}
[data-v-e3feda85]::file-selector-button {
  color: #006c69;
  background-color: #ffffff;
  border: 1px solid #006c69 !important;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
}
[data-v-e3feda85]::file-selector-button:hover {
    background-color: #006c69;
    color: #ffffff;
}
